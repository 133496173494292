<template>
  <router-view />
</template>

<script>
import paymentsModule from '../../store/payments'

export default {
  beforeCreate () {
    this.$store.registerModule('payments', paymentsModule)
  },
  destroyed () {
    this.$store.unregisterModule('payments')
  }
}
</script>
