import pagination from '../../utils/pagination'
import {
  PAYMENTS_FETCH_MANY_REQUEST, PAYMENTS_FETCH_MANY_SUCCESS, PAYMENTS_FETCH_MANY_FAILURE,
  PAYMENTS_SAVE_REQUEST, PAYMENTS_SAVE_SUCCESS, PAYMENTS_SAVE_FAILURE
} from './types'

export default {
  [PAYMENTS_FETCH_MANY_REQUEST] (state) {
    state.data = []
    state.loading = true
  },
  [PAYMENTS_FETCH_MANY_SUCCESS] (state, { data }) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
    state.validationErrors = {}
  },
  [PAYMENTS_FETCH_MANY_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [PAYMENTS_SAVE_REQUEST] (state, id) {
    state.saving = id
  },
  [PAYMENTS_SAVE_SUCCESS] (state) {
    state.saving = 0
  },
  [PAYMENTS_SAVE_FAILURE] (state, { validationErrors }) {
    state.validationErrors = validationErrors || {}
    state.saving = 0
  }
}
