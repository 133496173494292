export default {
  data ({ data }) {
    return data
  },
  errors ({ validationErrors }) {
    return validationErrors
  },
  isLoading ({ loading }) {
    return loading
  },
  savingId ({ saving }) {
    return saving
  },
  pagination ({ page }) {
    return page
  }
}
