<template>
  <b-container>
    <b-row>
      <b-col class="mb-2" cols="12" lg="6">
        <h1 class="font-weight-bold mb-0 text-primary">Payments</h1>
      </b-col>
      <b-col class="mb-2 mb-md-4" cols="12" lg="6">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row">
            <b-input-group>
              <b-form-datepicker :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }" v-model="filterStartDate"></b-form-datepicker>
              <div class="input-group-prepend input-group-append"><div class="input-group-text">-</div></div>
              <b-form-datepicker :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }" v-model="filterEndDate"></b-form-datepicker>
            </b-input-group>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-2 text-center" cols="12" v-if="isLoading">
        <b-spinner variant="secondary"></b-spinner>
      </b-col>
      <b-col class="mt-2 text-center" cols="12" v-if="!isLoading && !data.length">
        <b-card class="py-4 text-center">
          <p class="mb-2"><b-icon class="display-1 text-dark" icon="cloud-slash"></b-icon></p>
          <p class="font-weight-normal h5 mb-0 text-dark">There are no expected payments for this given time range.</p>
        </b-card>
      </b-col>
      <b-col class="mt-2" cols="12" v-if="!isLoading && data.length">
        <b-card class="mb-2" :key="`payment-${payment.id}`" v-for="payment in data">
          <b-card-title><router-link class="font-weight-bold" :to="`/customers/${get(payment, 'contract_meter.contract.site.customer.id')}`">{{ get(payment, 'contract_meter.contract.site.customer.company_name') }}</router-link></b-card-title>
          <b-card-sub-title>{{ get(payment, 'contract_meter.contract.site.address.address_snippet') }}</b-card-sub-title>
          <b-row class="mt-2">
            <b-col cols="12">
              <p class="text-muted">This is a payment for {{ moment(payment.payment_for).format('MMMM YYYY') }}</p>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12" md="6">
              <b-form-group label="Paid At" label-for="paid_at" :invalid-feedback="validationInvalidFeedback(errors, 'paid_at')" :state="validationState(errors, 'paid_at')">
                <b-form-datepicker :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit' }" v-model="payment.paid_at" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Expected Amount" label-for="amount" :invalid-feedback="validationInvalidFeedback(errors, 'amount')" :state="validationState(errors, 'amount')">
                <b-input-group prepend="£">
                  <b-form-input readonly id="amount" type="number" v-model="payment.amount" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Payment Reference" label-for="payment_ref" :invalid-feedback="validationInvalidFeedback(errors, 'payment_ref')" :state="validationState(errors, 'payment_ref')">
                <b-form-input id="payment_ref" v-model="payment.payment_ref" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Actual Amount" label-for="paid_amount" :invalid-feedback="validationInvalidFeedback(errors, 'paid_amount')" :state="validationState(errors, 'paid_amount')">
                <b-input-group prepend="£">
                  <b-form-input id="paid_amount" type="number" v-model="payment.paid_amount" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Notes" label-for="notes" :invalid-feedback="validationInvalidFeedback(errors, 'notes')" :state="validationState(errors, 'notes')">
                <b-textarea id="notes" v-model="payment.notes"></b-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="4" offset-md="4">
              <b-button block :to="`/energy/meters/${get(payment, 'contract_meter.meter.id')}`" variant="dark">View Meter</b-button>
            </b-col>
            <b-col cols="12" md="4">
              <b-button block @click.prevent="updatePayment(payment)" variant="secondary"><span v-if="savingId !== payment.id">Save</span><b-spinner class="mx-4" small v-if="savingId === payment.id" /></b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import get from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'
import momentMixin from '../../mixins/momentMixin'
import validationMixin from '../../mixins/validation'

export default {
  mixins: [momentMixin, validationMixin],
  created () {
    this.fetchManyPayments({
      startDate: this.moment(this.filterStartDate).format('YYYY-MM-DD'),
      endDate: this.moment(this.filterEndDate).format('YYYY-MM-DD'),
      page: this.$route.query.page
    })
  },
  data () {
    return {
      date: this.moment().toDate(),
      showOptions: ['missed']
    }
  },
  computed: {
    ...mapGetters('payments', ['data', 'isLoading', 'savingId', 'pagination', 'errors']),
    filterStartDate: {
      get () {
        return this.moment(this.date).startOf('month').toDate()
      },
      set (value) {
        this.date = value
      }
    },
    filterEndDate: {
      get () {
        return this.moment(this.date).endOf('month').toDate()
      },
      set (value) {
        this.date = value
      }
    }
  },
  methods: {
    ...mapActions('payments', ['fetchManyPayments', 'savePayment']),
    get,
    amountFormatter (val) {
      return new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(val / 100)
    },
    updatePayment (payment) {
      this.savePayment({
        id: payment.id,
        paid_at: payment.paid_at,
        payment_ref: payment.payment_ref,
        paid_amount: payment.paid_amount,
        notes: payment.notes
      }).then(response => {
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: 'Payment Updated Successfully',
          text: 'This payment has successfully been updated.'
        })
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Error Updating Payment',
            text: `Error for debugging: ${error.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    }
  },
  watch: {
    date () {
      this.fetchManyPayments({
        startDate: this.moment(this.filterStartDate).format('YYYY-MM-DD'),
        endDate: this.moment(this.filterEndDate).format('YYYY-MM-DD'),
        page: this.$route.query.page
      })
    }
  }
}
</script>

<style>

</style>
