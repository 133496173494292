import {
  PAYMENTS_FETCH_MANY_REQUEST, PAYMENTS_FETCH_MANY_SUCCESS, PAYMENTS_FETCH_MANY_FAILURE,
  PAYMENTS_SAVE_REQUEST, PAYMENTS_SAVE_SUCCESS, PAYMENTS_SAVE_FAILURE
} from './types'

export default {
  fetchManyPayments ({ commit }, { startDate, endDate, page = 1 }) {
    commit(PAYMENTS_FETCH_MANY_REQUEST)
    return window.axios.get('/payments', { params: { startDate, endDate, page } }).then(response => {
      commit(PAYMENTS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(PAYMENTS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  savePayment ({ commit }, payload) {
    commit(PAYMENTS_SAVE_REQUEST, payload.id)
    return window.axios.put(`/payments/${payload.id}`, payload).then(response => {
      commit(PAYMENTS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(PAYMENTS_SAVE_FAILURE, { validationErrors: error.response.data.errors })
      } else {
        commit(PAYMENTS_SAVE_FAILURE, { error })
      }
      throw error
    })
  }
}
